import React, { useRef, useEffect } from "react";
import { observer } from 'mobx-react-lite';
import {
    Stack, Divider, IconButton, Paper, Table, TableContainer,
    TableHead, Typography, TableCell, TableRow, tableCellClasses, styled,
    TableBody,
    TextField,
    LinearProgress,
    Box
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format, isWeekend } from "date-fns";
import dayjs from "dayjs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useStore } from "../../stores/store";
import PrintIcon from '@mui/icons-material/Print';
import { ScheduleTotalsByCompany } from "../../models/Schedule/ScheduleTotalsByCompany";

const style = (theme: any) => ({
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 20,
    right: 20,
    position: 'fixed',

});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

}));
function getNextSunday() {
    let dt = new Date();
    switch (dt.getDay()) {
        case 0:
            break;
        case 1:
            dt.setDate(dt.getDate() + 6);
            break;
        case 2:
            dt.setDate(dt.getDate() + 5);
            break;
        case 3:
            dt.setDate(dt.getDate() + 4);
            break;
        case 4:
            dt.setDate(dt.getDate() + 3);
            break;
        case 5:
            dt.setDate(dt.getDate() + 2);
            break;
        case 6:
            dt.setDate(dt.getDate() + 1);
            break;
    }

    dt.setDate(dt.getDate() - 7);
    return dt;
}
const getFontSize = () => {
    return 11;
}

export default observer(function ReportingMain() {
    const [selectedSunday, setSelectedSunday] = React.useState(getNextSunday());
    const { scheduleStore, userStore } = useStore();
    const { loadScheduleTotals, getScheduleTotals, loading } = scheduleStore;
    const { loadCompanyRoles, getCompanyRoles } = userStore;
    const [censusData, setCensusData] = React.useState(new Map());
    //let censusData = new Map();

    useEffect(() => {
        document.title = 'Reporting';
        let datestring = format(new Date(selectedSunday), 'MMddyyyy');
        loadCompanyRoles();
        loadScheduleTotals(datestring).then(() => {
            if (getScheduleTotals.values.length <= 0) {
                getScheduleTotals.forEach(u => {
                    censusData.set(u.cono, u.census);
                })
            }
        });
    }, [selectedSunday]);
    function ShiftKeyRolesMatching(role: string, selectedRole: string) {
        if (selectedRole.toUpperCase() == "NS" || selectedRole.toUpperCase() == "NURSE" || selectedRole.toUpperCase() == "RN" || selectedRole.toUpperCase() == "LPN") {
            if (role.toUpperCase() == "RN" || role.toUpperCase() == "LVN/LPN") {
                return true;
            }
        }
        if (selectedRole.toUpperCase() == "CNA") {
            if (role.toUpperCase() == "CNA") {
                return true;
            }
        }
        if(selectedRole.toUpperCase() == "CMT" || selectedRole.toUpperCase() == "CMA" || selectedRole.toUpperCase() == "QMA"){
            if(role.toUpperCase() ==  "CMA/CMT/QMA")
            {
                return true;
            }
        }
        return false;
    }

    const nextWeekClick = () => {
        let dt = new Date(selectedSunday);

        dt.setDate(dt.getDate() + 7);

        setSelectedSunday(dt);
    }
    const previousWeekClick = () => {
        let dt = new Date(selectedSunday);

        dt.setDate(dt.getDate() - 7);

        setSelectedSunday(dt);
    }
    const isWeekend = (date: dayjs.Dayjs) => {
        const day = date.day();

        return day !== 0;
    };
    const getFirstColumnWidth = () => {
        return 140;
    }
    const getDateForDOW = (dow: number) => {
        //sends 0 for sunday, 1 for monday, 2 for tuesday etc..
        let newdate = new Date(selectedSunday);

        //console.log('selected ' + selectedSunday);

        return newdate.setDate(selectedSunday.getDate() + dow);
    }
    const getBudgetPPDMin = (roleid:number) => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.roleid === roleid) {
                result = r.ppdbudgetmin;
            }
        })

        return result;
    }
    const getBudgetPPDMax = (roleid: number) => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.roleid === roleid) {
                result = r.ppdbudgetmax;
            }
        })

        return result;
    }
    const getDayDateFromView = (i: number) => {
        let result = new Date(selectedSunday);
        result.setDate(result.getDate() + i);
        return result;
    }
    const getScheduledHoursForDay = (i: number) => {
        let columnDate = new Date(selectedSunday);
        columnDate.setDate(columnDate.getDate() + i);
        let count: number = 0;
        // getUserResponsesForCalendarViewGrouped.filter(excludeRoles).forEach(d => {
        //     let multiply = countValues(d.usernames); //how many users are in this shift/shift time, we gotta add the hours together
        //     for (var i = 0; i < multiply; i++) {
        //         if (compareDatesIgnoringTime(d.start, columnDate)) {
        //             count += countHoursBetweenDates(d.start, d.end);
        //         }
        //     }
        // })
        return count;
    }

    const getBudgetPPD = () => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        // getCompanyRoles.forEach(r => {
        //     if (r.roleid === selectedRoleIDTab) {
        //         result = r.ppdbudget;
        //     }
        // })

        return result;
    }
    const getCalcPPD = (index: number) => {
        return getScheduledHoursForDay(index);// / currentCensus[index];
    }
    const getPPDVarianceColorOLD = (variance: number) => {
        if (variance < 0) {
            return 'red';
        }
        else if (variance > 6) {
            return 'blue';
        }
        else {
            return 'green';
        }
    }
    const getPPDVarianceColor = (ppd: number, roleid:number) => {
        console.log('ppd ' + ppd + ' and ' + getBudgetPPDMin(roleid));
        if (ppd < getBudgetPPDMin(roleid)) {
            return 'red';
        }
        else if (ppd > getBudgetPPDMax(roleid)) {
            return 'blue';
        }
        else {
            return 'green';
        }
    }
    const getVariance = (index: number) => {
        return Math.round(getBudgetPPD() - getCalcPPD(index));
    }
    const handleCensusChange = (id: string, newValue: number) => {
        console.log('try to change ' + newValue)
        const numValue = Number(newValue);
        if (!isNaN(numValue)) {
            setCensusData(new Map(censusData.set(id, numValue)));
        }
        console.log('we are now ' + censusData.get(id));
    };
    return (
        <>
            <Stack sx={{ display: 'flex' }} divider={<Divider orientation="horizontal" flexItem />}>
                <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={previousWeekClick}
                            color="inherit"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <DatePicker value={dayjs(selectedSunday)}
                            sx={{ marginTop: 2, marginBottom: 1, alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                            label="Week Of (Sunday)"
                            shouldDisableDate={isWeekend}
                            slotProps={{ textField: { size: 'small' } }}
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    return;
                                }
                                setSelectedSunday(newValue.toDate());
                            }} />
                    </LocalizationProvider>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={nextWeekClick}
                        color="inherit"
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                    {/* <Box flexGrow={1} display="flex" justifyContent="flex-end">
                            <IconButton
                                sx={{ marginRight: 2 }}
                                size="large"
                                color="primary">
                                <PrintIcon sx={{ fontSize: 30 }} color="primary" />
                            </IconButton>
                        </Box> */}
                </Stack>
                {!loading ?
                    <TableContainer component={Paper} sx={{ margin: 1, maxHeight:580 }}>
                        <Table stickyHeader={true} sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                            <TableHead>
                                <StyledTableRow key="100">
                                    <StyledTableCell width={getFirstColumnWidth()} align="center">Company</StyledTableCell>
                                    <StyledTableCell width={70} align="center">Role</StyledTableCell>
                                    <StyledTableCell width={70} align="center">Budget PPD</StyledTableCell>
                                    <StyledTableCell width={60} align="center">Census</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                SUNDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(0), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                MONDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(1), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                TUESDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(2), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                WEDNESDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(3), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                THURSDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(4), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center"><Stack>
                                        <Typography fontSize={14}>
                                            FRIDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(5), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                SATURDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(6), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                NEXT SUNDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(7), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {getScheduleTotals.map((comp) =>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.cono} - {comp.companyname}
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell width={70} align="center">
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.role}
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell width={70} align="center">
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.ppdbudget}
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell width={60} align="center">
                                            <TextField
                                                value={censusData.get(comp.cono) || 80}
                                                size='small'
                                                type='number'
                                                onChange={(e) => handleCensusChange(comp.cono, Number.parseInt(e.target.value))}
                                                sx={{ width: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center' }}
                                                id="outlined-basic"
                                                variant="outlined">
                                            </TextField>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ borderRight: 1, borderRightColor: '#cdcdcd' }} align="center">
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.sundaytotalhours.toFixed(2)} Total Hours
                                                </Typography>
                                                {comp.sundaytotalagencyhours > 0 ? <Typography fontWeight={'bold'} fontSize={getFontSize()}>
                                                    {comp.sundaytotalagencyhours.toFixed(2)} Agency Hours
                                                </Typography> :<></>}
                                                <Typography fontSize={getFontSize()} >
                                                    {(comp.sundaytotalhours / (censusData.get(comp.cono) || 80)).toFixed(2)} PPD
                                                </Typography>
                                                <Typography fontSize={getFontSize()} sx={{ color: getPPDVarianceColor((comp.sundaytotalhours / (censusData.get(comp.cono) || 80)),comp.roleid) }}>
                                                    {(comp.sundaytotalhours - ((censusData.get(comp.cono) || 80) * comp.ppdbudget)).toFixed(2)} Delta(hours)
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ borderRight: 1, borderRightColor: '#cdcdcd' }} align="center" >
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.mondaytotalhours.toFixed(2)} Total Hours
                                                </Typography>
                                                {comp.mondaytotalagencyhours > 0 ? <Typography fontWeight={'bold'} fontSize={getFontSize()}>
                                                    {comp.mondaytotalagencyhours.toFixed(2)} Agency Hours
                                                </Typography> :<></>}
                                                <Typography fontSize={getFontSize()}>
                                                    {(comp.mondaytotalhours / (censusData.get(comp.cono) || 80)).toFixed(2)} PPD
                                                </Typography>
                                                <Typography fontSize={getFontSize()} sx={{ color: getPPDVarianceColor((comp.mondaytotalhours / (censusData.get(comp.cono)|| 80)),comp.roleid) }}>
                                                    {(comp.mondaytotalhours - ((censusData.get(comp.cono)|| 80) * comp.ppdbudget)).toFixed(2)} Delta(hours)
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ borderRight: 1, borderRightColor: '#cdcdcd' }} align="center" >
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.tuesdaytotalhours.toFixed(2)} Total Hours
                                                </Typography>
                                                {comp.tuesdaytotalagencyhours > 0 ? <Typography fontWeight={'bold'} fontSize={getFontSize()}>
                                                    {comp.tuesdaytotalagencyhours.toFixed(2)} Agency Hours
                                                </Typography> :<></>}
                                                <Typography fontSize={getFontSize()}>
                                                    {(comp.tuesdaytotalhours / (censusData.get(comp.cono) || 80)).toFixed(2)} PPD
                                                </Typography>
                                                <Typography fontSize={getFontSize()} sx={{ color: getPPDVarianceColor((comp.tuesdaytotalhours / (censusData.get(comp.cono)|| 80)),comp.roleid) }}>
                                                    {(comp.tuesdaytotalhours - ((censusData.get(comp.cono)|| 80) * comp.ppdbudget)).toFixed(2)} Delta(hours)
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ borderRight: 1, borderRightColor: '#cdcdcd' }} align="center" >
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.wednesdaytotalhours.toFixed(2)} Total Hours
                                                </Typography>
                                                {comp.wednesdaytotalagencyhours > 0 ? <Typography fontWeight={'bold'} fontSize={getFontSize()}>
                                                    {comp.wednesdaytotalagencyhours.toFixed(2)} Agency Hours
                                                </Typography> :<></>}
                                                <Typography fontSize={getFontSize()}>
                                                    {(comp.wednesdaytotalhours / (censusData.get(comp.cono) || 80)).toFixed(2)} PPD
                                                </Typography>
                                                <Typography fontSize={getFontSize()} sx={{ color: getPPDVarianceColor((comp.wednesdaytotalhours / (censusData.get(comp.cono) || 80)),comp.roleid) }}>
                                                    {(comp.wednesdaytotalhours - ((censusData.get(comp.cono) || 80) * comp.ppdbudget)).toFixed(2)} Delta(hours)
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ borderRight: 1, borderRightColor: '#cdcdcd' }} align="center" >
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.thursdaytotalhours.toFixed(2)} Total Hours
                                                </Typography>
                                                {comp.thursdaytotalagencyhours > 0 ? <Typography fontWeight={'bold'} fontSize={getFontSize()}>
                                                    {comp.thursdaytotalagencyhours.toFixed(2)} Agency Hours
                                                </Typography> :<></>}
                                                <Typography fontSize={getFontSize()}>
                                                    {(comp.thursdaytotalhours / (censusData.get(comp.cono) || 80)).toFixed(2)} PPD
                                                </Typography>
                                                <Typography fontSize={getFontSize()} sx={{ color: getPPDVarianceColor((comp.thursdaytotalhours / (censusData.get(comp.cono) || 80)),comp.roleid) }}>
                                                    {(comp.thursdaytotalhours - ((censusData.get(comp.cono) || 80) * comp.ppdbudget)).toFixed(2)} Delta(hours)
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ borderRight: 1, borderRightColor: '#cdcdcd' }} align="center" >
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.fridaytotalhours.toFixed(2)} Total Hours
                                                </Typography>
                                                {comp.fridaytotalagencyhours > 0 ? <Typography fontWeight={'bold'} fontSize={getFontSize()}>
                                                    {comp.fridaytotalagencyhours.toFixed(2)} Agency Hours
                                                </Typography> :<></>}
                                                <Typography fontSize={getFontSize()}>
                                                    {(comp.fridaytotalhours / (censusData.get(comp.cono) || 80)).toFixed(2)} PPD
                                                </Typography>
                                                <Typography fontSize={getFontSize()} sx={{ color: getPPDVarianceColor((comp.fridaytotalhours / (censusData.get(comp.cono) || 80)),comp.roleid) }}>
                                                    {(comp.fridaytotalhours - ((censusData.get(comp.cono) || 80) * comp.ppdbudget)).toFixed(2)} Delta(hours)
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ borderRight: 1, borderRightColor: '#cdcdcd' }} align="center" >
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.saturdaytotalhours.toFixed(2)} Total Hours
                                                </Typography>
                                                {comp.saturdaytotalagencyhours > 0 ? <Typography fontWeight={'bold'} fontSize={getFontSize()}>
                                                    {comp.saturdaytotalagencyhours.toFixed(2)} Agency Hours
                                                </Typography> :<></>}
                                                <Typography fontSize={getFontSize()}>
                                                    {(comp.saturdaytotalhours / (censusData.get(comp.cono) || 80)).toFixed(2)} PPD
                                                </Typography>
                                                <Typography fontSize={getFontSize()} sx={{ color: getPPDVarianceColor((comp.saturdaytotalhours / (censusData.get(comp.cono) || 80)),comp.roleid) }}>
                                                    {(comp.saturdaytotalhours - ((censusData.get(comp.cono) || 80) * comp.ppdbudget)).toFixed(2)} Delta(hours)
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ borderRight: 1, borderRightColor: '#cdcdcd' }} align="center" >
                                            <Stack>
                                                <Typography fontSize={getFontSize()}>
                                                    {comp.sunday2totalhours.toFixed(2)} Total Hours
                                                </Typography>
                                                {comp.saturdaytotalagencyhours > 0 ? <Typography fontWeight={'bold'} fontSize={getFontSize()}>
                                                    {comp.sunday2totalagencyhours.toFixed(2)} Agency Hours
                                                </Typography> :<></>}
                                                <Typography fontSize={getFontSize()}>
                                                    {(comp.sunday2totalhours / (censusData.get(comp.cono) || 80)).toFixed(2)} PPD
                                                </Typography>
                                                <Typography fontSize={getFontSize()} sx={{ color: getPPDVarianceColor((comp.sunday2totalhours / (censusData.get(comp.cono) || 80)),comp.roleid) }}>
                                                    {(comp.sunday2totalhours - ((censusData.get(comp.cono) || 80) * comp.ppdbudget)).toFixed(2)} Delta(hours)
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <>
                        <LinearProgress sx={{ margin: 4 }} />
                    </>
                }
            </Stack>
        </>
    )
})